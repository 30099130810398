import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { Wizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import Initial from './steps/Initial';
import Success from './steps/Success';
import Error from './steps/Error';
const ChangeSubscription = ({ onClose }) => {
    const { paymentMethodFormFields } = useAppSelector(state => state.user);
    const onSidebarClose = () => {
        onClose();
    };
    return (_jsxs(Wizard, { initialStep: paymentMethodFormFields === null || paymentMethodFormFields === void 0 ? void 0 : paymentMethodFormFields.defaultStep, children: [_jsx(Initial, { onClose: onSidebarClose }), _jsx(Success, {}), _jsx(Error, {})] }));
};
export default ChangeSubscription;
