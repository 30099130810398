import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ChangeSubscriptionStrategy } from '@entities/current-user';
import { ReactComponent as InfoIcon } from '@app/assets/icons/wolfkit-solid/info-circle-solid.svg';
import { ReactComponent as AlertIcon } from '@app/assets/icons/wolfkit-solid/alert-triangle-solid.svg';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { Banner, StepContainer, StepTitle } from '@shared/ui/sidebars/shared';
import Button from '@shared/ui/buttons/Button';
import Icon from '@shared/ui/icons/Icon';
import ChangeTarifDetails from '../components/ChangeTarifDetails';
import useSubscriptionChange from '../useSubscriptionChange';
const BannerTitle = styled.span(props => ({
    display: 'block',
    fontWeight: 600,
    marginBottom: props.theme.spacing_sizes.xs * 0.25,
}));
const BannerText = styled.span(() => ({
    fontWeight: 400,
}));
const StepTitleStyled = styled(StepTitle)(() => ({
    fontWeight: 400,
}));
const TarifName = styled.span(() => ({
    fontWeight: 600,
}));
const Initial = ({ onClose }) => {
    const { t } = useTranslation();
    const { goToNextStep, isCreating, isDisabling, paymentMethodFormFields, } = useSubscriptionChange();
    const theme = useTheme();
    const getText = () => {
        const isUpgradeStrategy = paymentMethodFormFields.strategy === ChangeSubscriptionStrategy.Upgrade;
        return {
            title: Trans({
                ns: 'translation',
                i18nKey: isUpgradeStrategy ? 'settings.sidebars.change_subscription.steps.initial.upgrade_title' :
                    'settings.sidebars.change_subscription.steps.initial.downgrade_title',
                components: [
                    (_jsx(TarifName, {}, 'tarif-name')),
                ],
                values: {
                    tarifName: paymentMethodFormFields.planName,
                },
            }),
            details: isUpgradeStrategy ?
                Array.from(t('settings.sidebars.change_subscription.steps.initial.upgrade_details', {
                    returnObjects: true,
                })) :
                Array.from(t('settings.sidebars.change_subscription.steps.initial.downgrade_details', {
                    returnObjects: true,
                })),
            bannerIcon: _jsx(Icon, { IconComponent: isUpgradeStrategy ? InfoIcon : AlertIcon, color: isUpgradeStrategy ? theme.palette.primary.main : theme.palette.warning.main, size: 20 }),
            bannerTitle: (_jsx(_Fragment, { children: t(isUpgradeStrategy ? 'settings.sidebars.change_subscription.steps.initial.upgrade_hint.title' : 'settings.sidebars.change_subscription.steps.initial.downgrade_hint.title') })),
            bannerText: t(isUpgradeStrategy ?
                'settings.sidebars.change_subscription.steps.initial.upgrade_hint.text' :
                'settings.sidebars.change_subscription.steps.initial.downgrade_hint.text'),
        };
    };
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: onClose }, displayCancelBtn: true, children: _jsx(StepContainer, { title: _jsx(StepTitleStyled, { children: getText().title }), content: (_jsx(ChangeTarifDetails, { list: getText().details })), buttons: (_jsxs(_Fragment, { children: [_jsx(Banner, { icon: getText().bannerIcon, text: (_jsxs(_Fragment, { children: [_jsx(BannerTitle, { children: getText().bannerTitle }), _jsx(BannerText, { children: getText().bannerText })] })) }), _jsx(Button, { size: 'large', fullWidth: true, onClick: goToNextStep, disabled: isCreating || isDisabling, isLoading: isCreating || isDisabling, children: t('settings.sidebars.change_subscription.steps.initial.continue_btn') })] })), contentFlex: 1, buttonsGap: theme.spacing_sizes.l, containerPaddingTop: theme.spacing_sizes.xs * 3.25, containerGap: theme.spacing_sizes.xs * 3.25 }) }));
};
export default Initial;
