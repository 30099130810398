import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { BodyMedium } from '@src/components/styled/Typography';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepContainer } from '@shared/ui/sidebars/shared';
import IconWithText from '@shared/ui/display/IconWIthText';
import { ErrorIcon } from '@shared/ui/sidebars/icons';
import Button from '@shared/ui/buttons/Button';
import useSubscriptionChange from '../useSubscriptionChange';
const ErrorText = styled(BodyMedium)(() => ({
    maxWidth: 296,
    textAlign: 'center',
}));
const CardNumber = styled.span(() => ({
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    letterSpacing: '-0.176px',
}));
const Error = () => {
    const { t } = useTranslation();
    const { maskedCard, goToNextStep } = useSubscriptionChange();
    const theme = useTheme();
    return (_jsx(SidebarInner, { children: _jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(IconWithText, { IconElement: _jsx(ErrorIcon, {}), text: t('settings.sidebars.change_subscription.steps.error.title'), margin: `0 0 ${theme.spacing_sizes.m}px 0` }), _jsx(ErrorText, { children: Trans({
                            i18nKey: 'settings.sidebars.change_subscription.steps.error.text',
                            values: {
                                cardNumber: maskedCard || '',
                            },
                            components: [
                                _jsx(CardNumber, {}, 'card-number'),
                            ],
                        }) })] })), buttons: (_jsx(Button, { size: 'large', fullWidth: true, onClick: () => goToNextStep(), children: t('settings.sidebars.change_subscription.steps.error.continue_btn') })) }) }));
};
export default Error;
