var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/store/Hooks';
import { ChangeSubscriptionStrategy, closeSidebar, } from '@entities/current-user';
import { useCreateSubscriptionWithCheckoutMutation, useDisableSubscriptionMutation } from '@shared/api/current-user';
import { useWizard } from '@shared/ui/misc/Wizard';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
const useSubscriptionChange = () => {
    const { user } = useAppSelector(state => state.user);
    const { paymentMethodFormFields } = useAppSelector(state => state.user);
    const { current, goNext, goTo } = useWizard();
    const dispatch = useDispatch();
    const { navigate, routes } = useAppNavigation();
    const [createSubscriptionReq, { isLoading: isCreating, }] = useCreateSubscriptionWithCheckoutMutation();
    const [disableSubscriptionReq, { isLoading: isDisabling, }] = useDisableSubscriptionMutation();
    const getClientSecret = (priceId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (user) {
                const sessionLink = yield createSubscriptionReq({ priceId, userId: user.id });
                if (sessionLink.data) {
                    const event = sessionLink
                        .data;
                    window.location.replace(event.url);
                    // dispatch(
                    //     updatePaymentMethodForm({
                    //         clientSecret: event.checkoutSession,
                    //         eventId: event.id,
                    //     } as PaymentMethodFormFields),
                    // )
                    // goNext()
                }
            }
            // eslint-disable-next-line no-empty
        }
        catch (err) { }
    });
    const handleClose = () => {
        dispatch(closeSidebar());
        // this is used to remove url params with payment status
        navigate(routes.SETTINGS_SUBSCRIPTION);
    };
    const disableSubscription = (currentPlanId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (user) {
                const result = yield disableSubscriptionReq({
                    userId: user.id,
                    subscriptionId: currentPlanId,
                });
                if (result.data) {
                    goNext();
                }
                else {
                    goTo(2);
                }
            }
            // eslint-disable-next-line no-empty
        }
        catch (err) { }
    });
    const goToNextStep = () => {
        const formFields = paymentMethodFormFields;
        switch (current) {
            case 0:
                if (formFields.selectedPlanId) {
                    if (formFields.strategy === ChangeSubscriptionStrategy.Upgrade) {
                        getClientSecret(formFields.selectedPlanId);
                    }
                    else if (formFields.strategy === ChangeSubscriptionStrategy.Downgrade &&
                        formFields.currentPlanId) {
                        disableSubscription(formFields.currentPlanId);
                    }
                }
                break;
            case 1:
                handleClose();
                break;
            case 2:
                handleClose();
                break;
            default:
                break;
        }
    };
    return {
        maskedCard: (paymentMethodFormFields === null || paymentMethodFormFields === void 0 ? void 0 : paymentMethodFormFields.cardNumber) || '**89',
        paymentMethodFormFields: paymentMethodFormFields,
        goToNextStep,
        isCreating,
        isDisabling,
    };
};
export default useSubscriptionChange;
